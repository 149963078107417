.testimonials {
  display: flex;
  gap: 1rem;
  padding: 0 2rem;
  margin-left: 15%;
  margin-right: 15%;
}

.left-t {
  display: flex;
  flex: 1 1;
  gap: 2rem;
  flex-direction: column;
  text-transform: uppercase;
  color: white;
}

.left-t > :nth-child(1) {
  color: var(--orange);
  font-weight: bold;
}
.left-t > :nth-child(2),
.left-t > :nth-child(3) {
  font-weight: bold;
  font-size: 3rem;
}

.left-t > :nth-child(4) {
  text-transform: none;
  text-align: justify;
  letter-spacing: 2px;
  line-height: 40px;
}

.right-t {
  flex: 1;
  position: relative;
}

.right-t > img {
  position: absolute;
  width: 17rem;
  height: 20rem;
  object-fit: cover;
  right: 9rem;
  top: 2rem;
}

.right-t > :nth-child(1) {
  position: absolute;
  width: 17rem;
  height: 20rem;
  border: 2px solid orange;
  background-color: transparent;
  right: 11rem;
  top: 0.9rem;
}

.right-t > :nth-child(2) {
  position: absolute;
  width: 17rem;
  height: 19rem;
  right: 7rem;
  top: 4rem;
  background: var(--planCard);
}

.arrows {
  display: flex;
  gap: 1rem;
  position: absolute;
  bottom: 1rem;
  left: 3rem;
}

.arrows > img {
  width: 1.5rem;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .testimonials {
    flex-direction: column;
    margin-left: 0;
    margin-right: 0;
  }
  .left-t > :nth-child(1) {
    text-align: center;
  }
  .left-t > :nth-child(2),
  .left-t > :nth-child(3) {
    font-size: xx-large;
    text-align: center;
  }

  .left-t > :nth-child(4) {
    text-align: center;
  }
  .left-t > :nth-child(5) {
    text-align: center;
  }
  .right-t {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 6rem;
    gap: 2rem;
  }
  .right-t > img {
    flex-direction: column;

    position: relative;
    justify-content: center;
    margin-left: 12rem;
  }
  .right-t > :nth-child(1) {
    position: absolute;
    width: 17rem;
    height: 20rem;
    border: 2px solid orange;
    background-color: transparent;
    left: 1rem;
    top: 0.9rem;
  }

  .right-t > :nth-child(2) {
    position: absolute;
    width: 17rem;
    height: 19rem;
    right: 6rem;
    top: 4rem;
    background: var(--planCard);
  }
  .arrows {
    position: relative;
    /* flex-direction: row; */
    left: 12rem;
    top: 4rem;
    bottom: 4rem;
    /* text-align: center;
    align-items: center;
    justify-content: center; */
  }
  .arrows > img {
    display: flex;
    justify-content: center;
    width: 1.5rem;
    cursor: pointer;
  }
}
