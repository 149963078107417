.Reasons {
  padding: 0 2rem;
  display: flex;
  grid-template: 2rem;
  margin-left: 15%;
  margin-right: 15%;
  /* gap: 1rem; */
}
.left-r {
  flex: 1 1;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  grid-auto-rows: 1fr;
}

.left-r > img {
  object-fit: cover;
}

.left-r > :nth-child(1) {
  width: 12rem;
  grid-row: 1/3;
  height: 28rem;
}

.left-r > :nth-child(2) {
  width: auto;
  height: 16rem;
  grid-column: 2/4;
}

.left-r > :nth-child(3) {
  width: 14rem;
  grid-column: 2/3;
  grid-row: 2;
}

.left-r > :nth-child(4) {
  width: 10rem;
  grid-row: 2;
  grid-column: 3/4;
  height: 11.2rem;
}

.right-r {
  flex: 1 1;
  text-transform: uppercase;
  gap: 1rem;
  display: grid;
  margin-left: 4rem;
  margin-bottom: 4rem;
  margin-top: -1.5rem;
  justify-content: space-between;
}

.right-r > span {
  font-weight: bold;
  color: var(--orange);
}

.right-r > div {
  color: white;
  font-size: 3rem;
  font-weight: bold;
}

.details-r {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.details-r > div {
  display: flex;
  font-size: 1rem;
  gap: 1rem;
}

.details-r > div > img {
  width: 2rem;
  height: 2rem;
}

.partners {
  display: flex;
  gap: 1rem;
}

.partners > img {
  width: 2.5rem;
}

@media screen and (max-width: 768px) {
  .Reasons {
    flex-direction: column;
    margin-left: 1rem;
    margin-right: 1rem;
  }
  .left-r {
    flex: 1 2;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
    grid-auto-rows: 53%;
    /* display: grid;
    justify-content: space-between;
    overflow: hidden; */
    margin-left: -2rem;
    margin-right: -2rem;
  }
  .left-r > :nth-child(1) {
    width: auto;
    height: 28rem;
  }
  .left-r > :nth-child(2) {
    width: 18.5rem;
    height: 15rem;
  }
  .left-r > :nth-child(3) {
    width: 9rem;
    height: 12rem;
  }
  .left-r > :nth-child(4) {
    width: 8rem;
    height: 12rem;
  }
  .right-r {
    grid-auto-rows: auto;
    overflow: hidden;
    margin-top: 2rem;
    margin-left: 0rem;
    margin-right: 0rem;
  }
  .right-r > span {
    text-align: center;
    margin-top: 2rem;
  }
  .right-r > div {
    /* align-items: center; */
    font-size: xx-large;
    text-align: center;
    margin-bottom: 1rem;
  }
  .details-r {
    justify-content: start;
  }

  .partners {
    margin-left: 0;
    margin-right: 0;
    display: flex;
    justify-content: center;
  }
  .partners > img {
    justify-content: center;
  }
}
