.footer {
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
}

.footer > hr {
  position: absolute;
  width: 100%;
  margin-top: -2rem;
}

.Socials {
  display: flex;
  flex-direction: row;
  margin-top: 5rem;
  gap: 3rem;
}

.Socials > img {
  width: 2rem;
  height: 2rem;
  gap: 2rem;
}

.logoSection {
  display: flex;
  margin-top: 5rem;
  margin-bottom: 5rem;
}
.logoSection > img {
  display: flex;
  width: 10rem;
}

.blur-f1 {
  bottom: 0;
  right: 15%;
  width: 26rem;
  height: 12rem;
  filter: blur(200px);
  background-color: red;
}

.blur-f2 {
  bottom: 0;
  left: 15%;
  width: 26rem;
  height: 12rem;
  filter: blur(200px);
  background-color: red;
}
